import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';

import { DataService } from '../../services';
import { datetoLocalString } from '../../utility';

@Component({
	selector: 'app-date-range2',
	templateUrl: './date-range2.component.html',
	styleUrls: ['./date-range2.component.scss']
})
export class DateRange2Component implements OnInit {

	hoveredDate: NgbDate | null = null;
	@Output() onDateSelect = new EventEmitter<Date>();
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	private range: any = { fromDate: undefined, toDate: undefined };
	maxDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate()
	};
	private subscriptions: Subscription = new Subscription();
	selectedRange: string;
	valuedate = new Date();

	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private ds: DataService) {
		// this.fromDate = calendar.getToday();
		// this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
		const temp = this.ds.event.subscribe((data: any) => {
			if (data && data === 'clear') {
				this.range = { fromDate: undefined, toDate: undefined };
				this.fromDate = null;
				this.toDate = null;
				this.selectedRange = '';
			}
		});
		this.subscriptions.add(temp);
	}

	ngOnInit(): void { }

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	onDateSelection(date: NgbDate, rangepicker) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
			rangepicker.close();
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		this.range = {
			fromDate: this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : this.fromDate,
			toDate: this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : this.toDate
		}
		if (this.range.fromDate && this.range.toDate) {
			this.selectedRange = datetoLocalString(this.range.fromDate) + ' - ' + datetoLocalString(this.range.toDate)
		}
		this.onDateSelect.emit(this.range);
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

}
