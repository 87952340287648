export function SortArrObj(data, flag: string, key: string) {
    data.sort((a, b) => {
        if (flag === 'desc') {
            return b[key] - a[key];
        }
        return a[key] - b[key];
    });
    return data;
}

export function trim(data: any) {
    if (typeof data === 'string') {
        return data.trim();
    }
}