<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
    <div class="logo clearfix">
        <a [routerLink]="['']" class="logo-text float-left">
            <div class="logo-img">
                <img src="assets/img/collaps-icon.png" alt="Apex logo" />
            </div>
            <span class="text align-middle ml-2">
				My Ambar
				<!-- <img src="assets/img/logoadmin.png" alt="Apex logo" /> -->
			</span>
        </a>
        <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()"  ngbTooltip="Collaps menu section">
            <em #toggleIcon [ngClass]="{
					'ft-menu': config.layout.sidebar.collapsed,
					'ft-x': !config.layout.sidebar.collapsed
				}" class="toggle-icon">
			</em>
        </a>
        <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
            <em class="ft-x"></em>
        </a>
    </div>
</div>
<!-- Sidebar Header Ends -->

<div class="profile-detail">
    <div class="profile-title" *ngIf="adminDetails?.image"><img  src="{{adminDetails?.image}}">{{adminDetails?.letterInitials}}</div>
    <div class="profile-title text-uppercase"  *ngIf="adminDetails?.image == ''">{{adminDetails?.letterInitials}}</div>
    <p>{{adminDetails?.full_name}}</p>
    <button (click)="myProfile()">My Profile</button>
</div>

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" >
    <div class="nav-container">
        <ul class="navigation hiiii" appSidebarDropdown>
            <!-- First level menu -->
            <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path" level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false" [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
                [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
                <a appSidebarAnchorToggle title="{{menuItem.title | translate }}" *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink && menuItem.isvisible">
                    <em [ngClass]="[menuItem.icon]"></em>
                    <span  class="menu-title">{{menuItem.title | translate }}</span>
                    <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <a appSidebarAnchorToggle title="{{menuItem.title | translate }}" routerLink="{{menuItem.path}}" *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink && menuItem.isvisible">
                    <em [ngClass]="[menuItem.icon]"></em>
                    <span class="menu-title">{{menuItem.title | translate }}</span>
                    <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink  && menuItem.isvisible">
                    <em [ngClass]="[menuItem.icon]"></em>
                    <span class="menu-title">{{menuItem.title | translate }}</span>
                    <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <!-- Second level menu -->
                <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0  && menuItem.isvisible">
                    <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title" [hasSub]="menuSubItem.class.includes('has-sub') ? true: false" [path]="menuSubItem.path" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
                        [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
                        <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
                            <em [ngClass]="[menuSubItem.icon]"></em>
                            <span class="menu-title">{{menuSubItem.title | translate }}</span>
                            <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <a appSidebarAnchorToggle [ngbTooltip]="menuSubItem.title | translate" tooltipClass="my-custom-class" container="body" routerLink="{{menuSubItem.path}}" *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
                            <em  [ngClass]="[menuSubItem.icon]"></em>
                            <span class="menu-title">{{menuSubItem.title | translate }}</span>
                            <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
                            <em [ngClass]="[menuSubItem.icon]"></em>
                            <span class="menu-title">{{menuSubItem.title | translate }}</span>
                            <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <!-- Third level menu -->
                        <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                            <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [hasSub]="false" [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}" *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                                    <em [ngClass]="[menuSubsubItem.icon]"></em>
                                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                </a>
                                <ng-template #externalSubSubLinkBlock>
                                    <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                                        <em [ngClass]="[menuSubsubItem.icon]"></em>
                                        <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                                        <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                    </a>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
<!-- Sidebar Content Ends -->