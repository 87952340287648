import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators/map';
import { Observable } from 'rxjs/Observable';
import { saveAs } from '@progress/kendo-file-saver';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ILanguage, langArr } from 'app/interfaces';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	spinnerTxt: string = '';
	langArr: ILanguage[] = langArr;
    // public categoryContent: any = {};
	event: any = new EventEmitter<any>();
	public show: boolean;
	// private dataSource = new BehaviorSubject<any>(null);
	// data = this.dataSource.asObservable();

	constructor(private http: HttpClient) { }

	public get(url: string): Observable<any> {
		return this.http.get<any>(url).pipe(map(data => {
			return data;
		}));
	}

	public post(url: string, reqBody: any): Observable<any> {
		return this.http.post<any>(url, reqBody).pipe(map(data => {
			return data;
		}));
	}

	public paginatedPost(url: string, reqBody: any, data: any): Observable<any> {
		if (data) {
			url = this.formUrlParam(url, data);
		}
		return this.http.post<any>(url, reqBody).pipe(map(data => {
			return data;
		}));
	}

	public delete(url: string) {
		return this.http.delete<any>(url).pipe(map(data => {
			return data;
		}));
	}

	public put(url: string, reqBody: any): Observable<any> {
		return this.http.put<any>(url, reqBody).pipe(map(data => {
			return data;
		}));
	}

	public patch(url: string, reqBody: any): Observable<any> {
		return this.http.patch<any>(url, reqBody).pipe(map(data => {
			return data;
		}));
	}

	public upload(url: string, file: File) { }

	// for making query params for api urls
	public formUrlParam(url: string, data: any): string {
		let queryString: string = '';
		for (const key in data) {
			if (data.hasOwnProperty(key) && data[key]) {
				if (!queryString) {
					queryString = `?${key}=${data[key]}`;
				} else {
					queryString += `&${key}=${data[key]}`;
				}
			}
		}
		return url + queryString;
	}

	public downloadDocumentBlob(downLoadUrl: string, fileName: string, extension: string, requestType: string, payload: any = {}) {
		var HTTPOptions = {
			headers: new HttpHeaders({ 'Accept': 'application/octet-stream' }),
			observe: "response" as 'body',// to display the full response & as 'body' for type cast
			'responseType': 'blob' as 'json'
		}
		if (requestType === 'get') {
			return this.http.get(downLoadUrl, HTTPOptions).map((res: HttpResponse<any>) => {
				var blob = new Blob([res.body], { type: "application/octet-stream" });
				saveAs(blob, fileName + extension);
				return res;
			});
		} else {
			return this.http.post(downLoadUrl, payload, HTTPOptions).map((res: HttpResponse<any>) => {
				var blob = new Blob([res.body], { type: "application/octet-stream" });
				saveAs(blob, fileName + extension);
				return res;
			});
		}

	}
}
