import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes';

//COMPONENTS
import { NavbarComponent, FooterComponent, TableHeaderComponent, DateRangeComponent, DateRange2Component, OdometerComponent } from "./components";
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { TopMenuAnchorToggleDirective, TopMenuDropdownDirective, TopMenuLinkDirective, TopMenuDirective, NumberDirective, AlphabetOnlyDirective,
SidebarDirective, SidebarAnchorToggleDirective, SidebarDropdownDirective, SidebarLinkDirective, ToggleFullscreenDirective } from "./directives";

import {NgbDateCustomParserFormatter} from './services';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        TableHeaderComponent,
        DateRangeComponent,
        DateRange2Component,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        OdometerComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NumberDirective,
        AlphabetOnlyDirective,
        NgbModule,
        TranslateModule,
        NgxPermissionsModule
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        NumberDirective,
        AlphabetOnlyDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        TableHeaderComponent,
        DateRangeComponent,
        DateRange2Component,
        OdometerComponent
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
})
export class SharedModule { }
