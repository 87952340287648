import { RouteInfo } from './vertical-menu.metadata';
import { APP_ROUTES } from '../routes';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
	{
		path: APP_ROUTES.slash + APP_ROUTES.dashboard, title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.gaDashboard, title: 'Google Analytics', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: '', title: 'Management', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
		submenu: [
			{ path: APP_ROUTES.slash + APP_ROUTES.user, title: 'User Management', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: APP_ROUTES.slash + APP_ROUTES.adminManagement, title: 'Admin Management', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: APP_ROUTES.slash + APP_ROUTES.roleManagement, title: 'Role Management', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: APP_ROUTES.slash + APP_ROUTES.managePermissions, title: 'Manage-permissions', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
		]
	},
	// {
	// 	path: APP_ROUTES.slash + APP_ROUTES.user, title: 'User management', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	// },
	// {
	// 	path: APP_ROUTES.slash + APP_ROUTES.adminManagement, title: 'Admin Management', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	// },
	// {
	// 	path: APP_ROUTES.slash + APP_ROUTES.roleManagement, title: 'Role Management', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	// },
	{
		path: APP_ROUTES.slash + APP_ROUTES.serviceProviders, title: 'Service Provider', icon: 'ft-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.category, title: "Survivor's Toolkit", icon: 'ft-edit', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.questionnaire, title: 'Questionnaire', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.survey, title: 'Survey', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: '', title: 'Informative Pages', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
		submenu: [
			{ path: '/' + APP_ROUTES.about, title: 'About Us', icon: 'ft-info submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.contact, title: 'Contact Us', icon: 'ft-phone submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.terms, title: 'Terms', icon: 'ft-file-text submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.privacy, title: 'Privacy', icon: 'ft-lock submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.disclaimer, title: 'Disclaimer', icon: 'ft-alert-triangle submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// {
			// 	path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
			// 	submenu: [
			// 		{ path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// 		{ path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// 	]
			// },
		]
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.notification, title: 'Notifications', icon: 'ft-bell', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.reportsList, title: 'Reports', icon: 'ft-bell', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
];
