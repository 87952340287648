export class APP_ROUTES {
    public static root: string = '';
    public static slash: string = '/';
    public static previous: string = '../';
    public static new: string = 'new';
    
    //* Content layout */
    public static error: string = 'error';
    public static verification: string = 'verification';
    public static login: string = 'login';
    public static forgotPassword: string = 'forgot-password';
    public static resetPassword: string = 'reset-password';

    //* Full Layoyt */
    public static view: string = 'view';
    public static track: string = 'track';
    public static dashboard: string = 'dashboard';
    public static gaDashboard: string = 'ga-dashboard';
    public static user: string = 'user';
    public static userDetail: string = 'user-detail';
    public static serviceProviders: string = 'service-provider';
    public static category: string = 'category';
    public static questionnaire: string = 'questionnaire';
    public static about: string = 'about-us';
    public static contact: string = 'contact-us';
    public static terms: string = 'terms';
    public static privacy: string = 'privacy';
    public static disclaimer: string = 'disclaimer';
    public static adminManagement: string = 'admin-management';
    public static roleManagement: string = 'role-management';
    public static managePermissions: string = 'manage-permissions';
    public static notification: string = 'notifications';
    public static rating: string = 'ratings';
    public static myProfile: string = 'profile';
    public static survey: string = 'survey';
    public static report: string = 'report';
    public static viewReport: string = 'view-report';
    public static reportsList: string = 'reports'
}