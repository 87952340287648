<nav class="admin-dashboard-header navbar navbar-expand-lg navbar-light header-navbar bg-w {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
    <div class="container-fluid navbar-wrapper">
        <div class="navbar-header d-flex">
            <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse" (click)="toggleSidebar()">
                <em class="ft-menu font-medium-3"></em>
            </div>
            <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
                <li class="nav-item mr-2 d-none d-lg-block">
                    <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen (click)="ToggleClass()"><em class=" {{toggleClass}} font-medium-3"></em></a>
                </li>
                <li class="nav-item mr-2 d-flex calender-search" *ngIf="show">
                    <label><em class="ng-tns-c125-2 ft-filter" ng-reflect-ng-class="ft-users"></em> Filter:</label>
                    <app-date-range2 (onDateSelect)="onDateSelect($event)"></app-date-range2>
                    <button class="btn btn-info btn-md btn-raised mb-0 ml-2 mr-2" (click)="onApplyFilter()">
						<i class="fa fa-chevron-right" aria-hidden="true"></i>
					</button>
                    <button class="btn btn-outline-secondary btn-md btn-raised mb-0 ml-1" (click)="onReset()" placement="right" ngbTooltip="Reset" [openDelay]="300">
						<i class="ft-rotate-ccw"></i>
					</button>
                </li>
                <!-- <li class="nav-item nav-search">
					<a class="nav-link nav-link-search" id="navbar-search" href="javascript:"
						(click)="toggleSearchOpenClass(true)"><em class="ft-search font-medium-3"></em></a>
					<div class="search-input {{searchOpenClass}}">
						<div class="search-input-icon">
							<em class="ft-search font-medium-3"></em>
						</div>
						<input [formControl]="control" #search class="input" type="text" placeholder="Explore Apex..."
							tabindex="0" autofocus data-search="template-search" [appAutocomplete]="autocomplete"
							(keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
						<div class="search-input-close" (click)="toggleSearchOpenClass(false)">
							<em class="ft-x font-medium-3"></em>
						</div>

						<app-autocomplete #autocomplete="appAutocomplete">
							<ng-template appAutocompleteContent>
								<ng-container *ngIf="(listItems | filter: control.value) as result">
									<app-option #searchResults *ngFor="let option of result" [url]="option.url"
										[value]="option.name" (click)="redirectTo(option.url);"
										(mouseenter)="removeActiveClass()">
										<div class="d-flex align-items-center justify-content-between w-100">
											<div class="d-flex align-items-center justify-content-start">
												<em class="{{option.icon}} mr-2"></em>
												<span>{{ option.name }}</span>
											</div>
										</div>
									</app-option>
									<app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
								</ng-container>
							</ng-template>
						</app-autocomplete>
					</div>
				</li> -->
            </ul>
            <div class="navbar-brand-center">
                <div class="navbar-header">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <div class="logo">
                                <a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                                    <div class="logo-img"><img class="logo-img" alt="logo" [src]="logoUrl"></div>
                                    <span class="text">NASSCOM</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <!-- <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
						<a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language"
							id="dropdown-flag" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img
								class="langimg selected-flag" [src]="selectedLanguageFlag" alt="flag" /><span
								class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
						<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left"
							aria-labelledby="dropdown-flag">
							<a class="dropdown-item" href="javascript:;" data-language="en"
								(click)="ChangeLanguage('en')"><img class="langimg mr-2" src="assets/img/flags/us.png"
									alt="flag" /><span class="font-small-3">English</span></a><a class="dropdown-item"
								href="javascript:;" data-language="es" (click)="ChangeLanguage('es')"><img
									class="langimg mr-2" src="assets/img/flags/es.png" alt="flag" /><span
									class="font-small-3">Spanish</span></a><a class="dropdown-item" href="javascript:;"
								data-language="pt" (click)="ChangeLanguage('pt')"><img class="langimg mr-2"
									src="assets/img/flags/pt.png" alt="flag" /><span
									class="font-small-3">Portuguese</span></a>
							<a class="dropdown-item" href="javascript:;" data-language="de"
								(click)="ChangeLanguage('de')"><img class="langimg mr-2" src="assets/img/flags/de.png"
									alt="flag" /><span class="font-small-3">German</span></a>
						</div>
					</li> -->
                    <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
						<a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification"
							href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><em
								class="ft-bell font-medium-3"></em><span
								class="notification badge badge-pill badge-danger">1</span></a>
						<ul ngbDropdownMenu
							class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
							<li class="dropdown-menu-header">
								<div
									class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
									<div class="d-flex">
										<em class="ft-bell font-medium-3 d-flex align-items-center mr-2"></em>
										<span class="noti-title">1 New Notification</span>
									</div>
									<span class="text-bold-400 cursor-pointer">Mark all as read</span>
								</div>
							</li>
							<li class="scrollable-container" [perfectScrollbar]>
								<a class="d-flex justify-content-between" href="javascript:void(0)">
									<div class="media d-flex align-items-center">
										<div class="media-left">
											<div class="mr-3">
												<img class="avatar" src="assets/img/portrait/small/avatar-s-20.png"
													alt="avatar" height="45" width="45" />
											</div>
										</div>
										<div class="media-body">
											<h6 class="m-0">
												<span>Kate Young</span><small
													class="grey lighten-1 font-italic float-right">5 mins
													ago</small>
											</h6>
											<small class="noti-text">Commented on your photo</small>
											<h6 class="noti-text font-small-3 m-0">
												Great Shot John! Really enjoying the composition on this
												piece.
											</h6>
										</div>
									</div>
								</a>
							</li>
							<li class="dropdown-menu-footer">
								<div
									class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
									Read All Notifications
								</div>
							</li>
						</ul>
					</li> -->
                    <!-- <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
						<a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
							href="javascript:;">
							<div class="user d-md-flex d-none mr-2">
								<span class="text-right">Admin</span>
							</div>
						</a>
						<a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
							href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
							<div class="user d-md-flex d-none mr-2">
								<span class="text-right">Admin</span>
							</div>
							<img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35"
								width="35" />
						</a>
						<div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
							aria-labelledby="dropdownBasic2" ngbDropdownMenu>
							<ng-container *ngFor="let item of profileDd">
								<a class="dropdown-item" routerLink="{{ item.path }}" 
								(click)="item.clickable ? auth.logout() : false">
									<div class="d-flex align-items-center">
										<em class="{{item.icon}} mr-2"></em><span>{{ item.title }}</span>
									</div>
								</a>
								<div class="dropdown-divider"></div>
							</ng-container>
						</div>
					</li> -->
                    <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
						<a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();">
							<em class="ft-align-right font-medium-3"></em>
						</a>
					</li> -->
                    <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
						<strong>Admin</strong>
					</li> -->
                    <li class="nav-item d-none d-lg-block mr-2 mt-1 cursor-pointer header-logout text-white" (click)="auth.logout()">
                        <i class="fa fa-user mr-2" aria-hidden="true"></i>Sign out
                    </li>
                </ul>
            </div>
        </div>
    </div>



    <div class="page-title admin-title mb-3">Welcome back, <span class="mr-1">{{adminDetails?.full_name}}</span>!</div>

</nav>