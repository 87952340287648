export function columns(key: string) {
    if (key === 'users') {
        const userColumn = [
            // {
            //     prop: 'selected',
            //     name: '',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     headerCheckboxable: false,
            //     checkboxable: false,
            //     width: 50,
            //     maxWidth: 50
            // },
            // {
            //     prop: 'id',
            //     name: 'Id',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     // width: 'auto',
            // },
            {
                prop: 'full_name',
                name: 'Name',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'gender',
                name: 'Gender',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'age_group',
                name: 'Age group',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'is_password',
                name: 'Is password set',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'createdAt',
                name: 'Created date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'emergency_contact_count',
                name: 'Emergency contacts',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            // {
            //     prop: 'location',
            //     name: 'Last Location',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // },
            {
                prop: 'platform',
                name: 'Platform',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'statusKey',
                name: 'Status',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto' badge badge-pill bg-light-success,
            }
        ];
        return userColumn;
    } else if (key === 'Service Provider Directory') {
        const serviceColumn = [
            // {
            //     prop: 'selected',
            //     name: '',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     headerCheckboxable: false,
            //     checkboxable: false,
            //     width: 50,
            //     maxWidth: 50
            // },
            // {
            //     prop: 'id',
            //     name: 'Id',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     maxWidth: 50
            // },
            {
                prop: 'ServiceProviderCategories',
				name: 'Category Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all',
                width: 300
            },
            // {
            //     prop: 'provider_name',
            //     name: 'Service provider',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // },
            {
                prop: 'type',
                name: 'Type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'phone_number',
                name: 'Contact',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all'
                // width: 'auto',
            },
            {
                prop: 'city',
                name: 'City',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'pincode',
                name: 'Pincode',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'paid_service',
                name: 'Paid',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'createdAt',
                name: 'Created Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
        ];
        return serviceColumn;
    } else if (key === 'Questionnaire') {
        const questionnaireCloumn = [
            {
                prop: 'category_name.en',
				name: 'Category Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
        ];
        return questionnaireCloumn;
    } else if (key === 'Survey') {
        const surveyCloumn = [
            {
                prop: 'name',
				name: 'Survey Name',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all',
                width:150
            },
            {
                prop: 'published',
				name: 'Published',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
			//	cellClass: 'd-flex align-items-center break-all',
                width: 100
            
            },
            {
                prop: 'survey_start_date',
				name: 'Start Date',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
			//	cellClass: 'd-flex align-items-center break-all',
                width: 150
            },
            {
                prop: 'survey_expiry_date',
				name: 'Expiry Date',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
			//	cellClass: 'd-flex align-items-center break-all',
                width: 150
            },
            {
                prop: 'total_survey_sent',
				name: 'Delivery Count',
				sortable: false,
                width: 150
            },
            {
                prop: 'survey_open_count',
				name: 'View Count',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
			//	cellClass: 'd-flex align-items-center break-all',
                width: 150
            },
            {
                prop: 'survey_response_count',
				name: 'Completed Count',
				sortable: false,
				// draggable: false,
				// canAutoResize: true,
			//	cellClass: 'd-flex align-items-center break-all',
                width: 150
            },
            
        ];
        return surveyCloumn;
    } else if (key === 'Survey Report') {
        const surveyReportCloumn = [
            {
                prop: 'full_name',
				name: 'Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all',
                width:100
            },
            {
                prop: 'mobile',
				name: 'Mobile',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all',
                width: 100
            
            },
            {
                prop: 'createdAt',
				name: 'Date',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
        ];
        return surveyReportCloumn;
    }
     else if (key === 'roles') {
        const rolesColumns = [
            {
                prop: 'full_name',
				name: 'Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'email',
				name: 'Email',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'organization_name',
				name: 'Organisation',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'phone_number',
				name: 'Phone No.',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'roleName',
				name: 'Role',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
        ];
        return rolesColumns;
    } else if (key === 'role') {
        const roleColumns = [
            {
                prop: 'name',
				name: 'Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            }
        ];
        return roleColumns;
    }

    else if (key === 'notification') {
        const notificationColumns = [
            {
                prop: 'title',
				name: 'Title',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'body',
				name: 'Message',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            // {
            //     prop: 'createdAt',
			// 	name: 'Created At',
			// 	sortable: false,
			// 	draggable: false,
			// 	canAutoResize: true,
			// 	cellClass: 'd-flex align-items-center break-all'
            // },
          
        ];
        return notificationColumns;
    }
    else if (key === 'dasboardProviderTable') {
        const topCityColumn = [
            {
                prop: 'city',
				name: 'City Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all',
                width: 300
            },
            {
                prop: 'count',
                name: 'Count',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            }
        ];
        return topCityColumn;
    }

    else if (key === 'toolkitProviderTable') {
        const toolkits = [
            {
                prop: 'name',
				name: 'Title',
				sortable: false,
				draggable: false,
				canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all',
                width: 300
            },
            {
                prop: 'total',
                name: 'Users',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            }
        ];
        return toolkits;
    }

    else if (key === 'spRatings') {
        const spRatingsColumns = [
          
            // {
            //     prop: 'full_name',
			// 	name: 'Name',
			// 	sortable: false,
			// 	draggable: false,
			// 	canAutoResize: true,
			// 	cellClass: 'd-flex align-items-center break-all'
            // },
            // {
            //     prop: 'phone_number',
			// 	name: 'Phone Number',
			// 	sortable: false,
			// 	draggable: false,
			// 	canAutoResize: true,
			// 	cellClass: 'd-flex align-items-center break-all'
            // },
            // {
            //     prop: 'rating',
			// 	name: 'Rating',
			// 	sortable: false,
			// 	draggable: false,
            //     canAutoResize: true,
            //     width: 100,
			// 	cellClass: 'd-flex align-items-center break-all'
            // }
          
        ];
        return spRatingsColumns;
    }

}
