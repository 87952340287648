import {
	ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexGrid, ApexDataLabels, ApexStroke, ApexTitleSubtitle, ApexTooltip,
	ApexLegend, ApexPlotOptions, ApexFill, ApexMarkers, ApexTheme, ApexNonAxisChartSeries, ApexResponsive
} from "ng-apexcharts";

export type ChartOptions = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	colors: string[],
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis | ApexYAxis[],
	title: ApexTitleSubtitle;
	dataLabels: ApexDataLabels,
	stroke: ApexStroke,
	grid: ApexGrid,
	legend?: ApexLegend,
	tooltip?: ApexTooltip,
	plotOptions?: ApexPlotOptions,
	labels?: string[],
	fill: ApexFill,
	markers?: ApexMarkers,
	theme: ApexTheme,
	responsive: ApexResponsive[]
};

const sliceTheme: any[] = [
    { theme: '#975aff' },
    { theme: '#40c057' },
    { theme: '#f77e17' },
    { theme: '#2f8be6' },
    { theme: '#ea5152' },
    { theme: '#562db7' },
    { theme: '#616d89' },
    { theme: '#b02940' },
    { theme: '#ef00ff' },
    { theme: '#ff0c00' },
    { theme: '#fbff00' },
    { theme: '#00ffdc' },
    { theme: '#ff0000' },
    { theme: '#1000ff' },
    { theme: '#000000' }
]

export function mapTheme(data: any) {
    const arr: any[] = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].value) {
            arr.push(sliceTheme[i].theme)
        }
    }
    return arr;
}

export const ApexChartData: any = {
    spPie: {
        chart: {
			type: 'pie',
			height: 225
		},
		colors: [],
		labels: [],
		series: [],
		legend: {
			show: true,
			itemMargin: {
				horizontal: 2
			},
		},
		responsive: [{
			breakpoint: 576,
			options: {
				chart: {
					width: 300
				},
				legend: {
					position: 'bottom'
				}
			}
		}]
    },
    whatCanIDoPie: {
        chart: {
			type: 'pie',
			height: 225
		},
		colors: [],
		labels: [],
		series: [],
		legend: {
			show: false,
			itemMargin: {
				horizontal: 2
			},
		},
		responsive: [{
			breakpoint: 576,
			options: {
				chart: {
					width: 300
				},
				legend: {
					position: 'bottom'
				}
			}
		}]
    }
}