import * as Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import { Chart } from '../../interfaces';

// line area chart
export function LineArea(source: any, delay: number = 40, duration: number = 200, height: string = '') {
    var seq = 0,
        delays = delay,
        durations = duration;
    const data: Chart = {
        type: 'Line',
        data: source,
        options: {
            showArea: true,
            fullWidth: true,
            lineSmooth: Chartist.Interpolation.none(),
            axisX: {
                showGrid: true,
            },
            axisY: {
                low: 0,
                scaleMinSpace: 50,
                // referenceValue: 5                
            },
            plugins: [
                ChartistTooltip({
                    appendToBody: true,
                    pointClass: 'ct-point-circle'
                })
            ],
            height: height,
        },
        responsiveOptions: [
            ['screen and (max-width: 640px) and (min-width: 381px)', {
                axisX: {
                    labelInterpolationFnc: function (value, index) {
                        return index % 2 === 0 ? value : null;
                    }
                }
            }],
            ['screen and (max-width: 380px)', {
                axisX: {
                    labelInterpolationFnc: function (value, index) {
                        return index % 3 === 0 ? value : null;
                    }
                }
            }]
        ],
        events: {
            created(data: any): void {
                seq = 0;
                var defs = data.svg.elem('defs');
                defs.elem('linearGradient', {
                    id: 'gradient2',
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                }).elem('stop', {
                    offset: 0,
                    'stop-opacity': '0.2',
                    'stop-color': 'transparent'
                }).parent().elem('stop', {
                    offset: 1,
                    'stop-opacity': '0.2',
                    'stop-color': '#358be6'
                });
            },
            draw(data: any): void {
                seq++;
                // var circleRadius = 4;
                if (data.type === 'line') {
                    // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
                    data.element.animate({
                        opacity: {
                            // The delay when we like to start the animation
                            begin: seq * delays,
                            // Duration of the animation
                            dur: durations,
                            // The value where the animation should start
                            from: 0,
                            // The value where it should end
                            to: 1
                        }
                    });
                } else if (data.type === 'label' && data.axis === 'x') {
                    data.element.animate({
                        y: {
                            begin: seq * delays,
                            dur: durations,
                            from: data.y + 100,
                            to: data.y,
                            // We can specify an easing function from Chartist.Svg.Easing
                            easing: 'easeOutQuart'
                        }
                    });
                } else if (data.type === 'label' && data.axis === 'y') {
                    data.element.animate({
                        x: {
                            begin: seq * delays,
                            dur: durations,
                            from: data.x - 100,
                            to: data.x,
                            easing: 'easeOutQuart'
                        }
                    });
                } else if (data.type === 'point') {
                    // var circle = new Chartist.Svg('circle', {
                    //     cx: data.x,
                    //     cy: data.y,
                    //     r: 4,
                    //     'ct:value': data.value.y,
                    //     'ct:meta': data.meta,
                    //     style: 'pointer-events: all !important',
                    //     class: 'ct-point-circle'
                    // });
                    // data.element.replace(circle);
                    data.element.animate({
                        x1: {
                            begin: seq * delays,
                            dur: durations,
                            from: data.x - 100,
                            to: data.x,
                            easing: 'easeOutQuart'
                        },
                        x2: {
                            begin: seq * delays,
                            dur: durations,
                            from: data.x - 100,
                            to: data.x,
                            easing: 'easeOutQuart'
                        },
                        opacity: {
                            begin: seq * delays,
                            dur: durations,
                            from: 0,
                            to: 1,
                            easing: 'easeOutQuart'
                        }
                    });
                   
                } else if (data.type === 'grid') {
                    // Using data.axis we get x or y which we can use to construct our animation definition objects
                    var pos1Animation = {
                        begin: seq * delays,
                        dur: durations,
                        from: data[data.axis.units.pos + '1'] - 30,
                        to: data[data.axis.units.pos + '1'],
                        easing: 'easeOutQuart'
                    };

                    var pos2Animation = {
                        begin: seq * delays,
                        dur: durations,
                        from: data[data.axis.units.pos + '2'] - 100,
                        to: data[data.axis.units.pos + '2'],
                        easing: 'easeOutQuart'
                    };

                    var animations = {};
                    animations[data.axis.units.pos + '1'] = pos1Animation;
                    animations[data.axis.units.pos + '2'] = pos2Animation;
                    animations['opacity'] = {
                        begin: seq * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'easeOutQuart'
                    };

                    data.element.animate(animations);
                }
                // if (data.type === 'point') {
                    
                // }
                else if (data.type === 'label') {
                    // adjust label position for rotation
                    const dX = data.width / 2 + (30 - data.width)
                    data.element.attr({ x: data.element.attr('x') - dX })
                }
            }
        }
    }
    return data;
}

// pie/donut chart
export function DonutChart(source: any) {
    // var sum = function(a, b) { return a + b };
    const data: Chart = {
        type: 'Pie',
        data: source,
        options: {
            donut: true,
            startAngle: 0,
            showLabel: false,
            labelInterpolationFnc: function (value) {
                var total = source.series.reduce((prev, series) => {
                    return prev + series.value;
                }, 0);
                return total;
            }
        },
        events: {
            draw(data: any): void {
                if (data.type === 'slice') {
                    // Get the total path length in order to use for dash array animation
                    var pathLength = data.element._node.getTotalLength();

                    // Set a dasharray that matches the path length as prerequisite to animate dashoffset
                    data.element.attr({
                        'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
                    });

                    // Create animation definition while also assigning an ID to the animation for later sync usage
                    var animationDefinition: any = {
                        'stroke-dashoffset': {
                            id: 'anim' + data.index,
                            dur: 500 * data.value / data.totalDataSum,
                            from: -pathLength + 'px',
                            to: '0px',
                            // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
                            fill: 'freeze'
                        }
                    };

                    // If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
                    if (data.index !== 0) {
                        animationDefinition['stroke-dashoffset']['begin'] = 'anim' + (data.index - 1) + '.end';
                    }

                    // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
                    data.element.attr({
                        'stroke-dashoffset': -pathLength + 'px'
                    });

                    // We can't use guided mode as the animations need to rely on setting begin manually
                    // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
                    data.element.animate(animationDefinition, false);

                    // add (naive) bounce
                    if (data.endAngle === 360) {
                        var index = data.index;
                        var dur = 1000 * data.value / data.totalDataSum / 2;
                        var from = 0;
                        var to = -pathLength / 3;

                        for (var i = 0; i < 4; i++) {
                            data.element.animate({
                                'stroke-dashoffset': {
                                    id: 'anim' + (index + 1),
                                    dur: dur,
                                    from: from + 'px',
                                    to: to + 'px',
                                    fill: 'freeze',
                                    begin: 'anim' + index + '.end'
                                }
                            }, false);

                            index++;
                            dur /= 1.25;

                            var t = from;
                            from = to;
                            to = t / 2.5;
                        }
                    }
                }
                if (data.type === 'label') {
                    if (data.index === 0) {
                        data.element.attr({
                            dx: data.element.root().width() / 2,
                            dy: data.element.root().height() / 2
                        });
                    } else {
                        data.element.remove();
                    }
                }
            }
        }
    }
    return data;
}
