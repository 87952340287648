import { environment } from '../../../environments/environment';

export class ApiRoutes {
    // api base url
    static apiV1: string = 'api/w/v1/';
    static apiV2: string = 'api/m/v1/';
    public static apiBaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV1;
    public static api2BaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV2;
    
    // categories
    public static get languages(): string { return this.api2BaseUrl + 'categories/getLanguages'; }

    // dashboard
    public static get dashboard(): string { return this.apiBaseUrl + 'dashboard/report'; }
    public static get spStatsDownload(): string { return this.apiBaseUrl + 'dashboard/serviceProviderReport'; }
    public static get categoryStatsDownload(): string { return this.apiBaseUrl + 'dashboard/whatcanReport'; }
    public static get userStatsDownload(): string { return this.apiBaseUrl + 'dashboard/userReport'; }


    // auth
    public static get login(): string { return this.apiBaseUrl + 'users/authorize'; }
    public static get forgotPassword(): string { return this.apiBaseUrl + 'users/forgot_password'; }
    public static get resetPassword(): string { return this.apiBaseUrl + 'users/reset_password'; }

    // user management
    public static get user(): string { return this.apiBaseUrl + 'users'; }
    public static get trackUserActivity(): string { return this.apiBaseUrl + 'track/'; }
    public static get userAdd(): string { return this.apiBaseUrl + 'users/add'; }
    public static get userEdit(): string { return this.apiBaseUrl + 'users/edit/'; }
    public static get userStatus(): string { return this.apiBaseUrl + 'users/status/'; }
    public static get userView(): string { return this.apiBaseUrl + 'users/view/'; }
    public static get deleteUser(): string { return this.apiBaseUrl + 'users/delete/'; }
    public static get restoreUser(): string { return this.apiBaseUrl + 'admins/restore_user/'; }

    // service provider
    public static get spList(): string { return this.apiBaseUrl + 'service/get_service_provider'; }
    public static get spAdd(): string { return this.apiBaseUrl + 'service/add_service'; }
    public static get spView(): string { return this.apiBaseUrl + 'service/view/'; }
    public static get spRating(): string { return this.apiBaseUrl + 'service/rating_list/'; }
    public static get spUpdate(): string { return this.apiBaseUrl + 'service/'; }
    public static get spDelete(): string { return this.apiBaseUrl + 'service/delete/'; }
    public static get editRating(): string { return this.apiBaseUrl + 'service/edit/rating/'; }

    // toolkit module
    public static get createCategory(): string { return this.apiBaseUrl + 'survivor/add_category'; }
    public static get editCategory(): string { return this.apiBaseUrl + 'survivor/'; }
    public static get getCategory(): string { return this.apiBaseUrl + 'survivor/get_categories'; }
    public static get getCategoryName(): string { return this.apiBaseUrl + 'survivor/get_category_names'; }
    public static get deleteCategory(): string { return this.apiBaseUrl + 'survivor/delete/'; }
    public static get getCategoryById(): string { return this.apiBaseUrl + 'survivor/view/'; }

    // Questionaire module
    public static get createQuestionaire(): string { return this.apiBaseUrl + 'risk/add_questionaire'; }
    public static get editQuestionaire(): string { return this.apiBaseUrl + 'risk/'; }
    public static get getQuestionaires(): string { return this.apiBaseUrl + 'risk/get_questionaires'; }
    public static get deleteQuestionaires(): string { return this.apiBaseUrl + 'risk/delete/'; }
    public static get getQuestionaire(): string { return this.apiBaseUrl + 'risk/view/'; }

      // Survey module
      public static get createSurvey(): string { return this.apiBaseUrl + 'surveys/add'; }
      public static get editSurvey(): string { return this.apiBaseUrl + 'surveys/update/'; }
      public static get getsurveyList(): string { return this.apiBaseUrl + 'surveys/list'; }
      public static get getSurvey(): string { return this.apiBaseUrl + 'surveys/view/'; }
      public static get publishSurvey(): string { return this.apiBaseUrl + 'surveys/publish/'; }
      public static get getsurveyResponse(): string { return this.apiBaseUrl + 'surveys/response/'; }
      public static get getsurveyReport(): string { return this.apiBaseUrl + 'surveys/response_list/'; }
      public static get getsurveyReportCSV(): string { return this.apiBaseUrl + 'surveys/report/'; }

    // informative pages
    public static get informativeList(): string { return this.apiBaseUrl + 'pages/getAll'; }
    public static get informativePageAdd(): string { return this.apiBaseUrl + 'pages/add'; }
    public static get informativePageEdit(): string { return this.apiBaseUrl + 'pages/'; }
    public static get informativePageById(): string { return this.apiBaseUrl + 'pages/view/'; }

    // role management
    public static get moduleList(): string { return this.apiBaseUrl + 'admins/modulesList'; }
    public static get adminList(): string { return this.apiBaseUrl + 'admins/'; }
    public static get adminPassword(): string { return this.apiBaseUrl + 'admins/update/password'; }
    public static get addAdminUser(): string { return this.apiBaseUrl + 'admins/add'; }
    public static get deleteAdminUser(): string { return this.apiBaseUrl + 'admins/delete/'; } 
    public static get viewAdminUser(): string { return this.apiBaseUrl + 'admins/view/'; }
    public static get rolesList(): string { return this.apiBaseUrl + 'roles/'; }
    public static get rolesListOnly(): string { return this.apiBaseUrl + 'roles'; }
    public static get rolesAdd(): string { return this.apiBaseUrl + 'roles/add'; }
    public static get getRoles(): string { return this.apiBaseUrl + 'roles/getNames'; }
    public static get viewRole(): string { return this.apiBaseUrl + 'roles/view/'; }
    public static get deleteRole(): string { return this.apiBaseUrl + 'roles/delete/'; }

    //notifications
    public static get notificationList(): string { return this.apiBaseUrl + 'admins/notification/list'; }
    public static get addnotification(): string { return this.apiBaseUrl + 'admins/bulkNotification'; }

    //GA
    public static get gaData(): string { return this.apiBaseUrl + 'ga'; }
    public static get gaDataPageView(): string { return this.apiBaseUrl + 'ga/pageViews'; }
    public static get gaDataPlatform(): string { return this.apiBaseUrl + 'ga/platforms'; }

    //Reports
    public static get reportData(): string { return this.apiBaseUrl + 'report/list'; }
    public static get reportServiceprovider(): string { return this.apiBaseUrl + 'report/getServiceProvider'; }
    public static get reportCities(): string { return this.api2BaseUrl + 'categories/getCities'; }
    public static get reportRawAttributes(): string { return this.apiBaseUrl + 'report/raw-attributes'; }

    //ACL
    public static get aclreportData(): string { return this.apiBaseUrl + 'acl/get-roles'; }
    public static get acladdpermissiont(): string { return this.apiBaseUrl + 'acl/add-module'; }
    public static get aclupdatepermissiont(): string { return this.apiBaseUrl + 'acl/update-module'; }
    public static get rolesupdatepermissionacl(): string { return this.apiBaseUrl + 'roles'; }
    public static get rolesCommonpermissionacl(): string { return this.apiBaseUrl + 'roles/update-roles'; }
   
}
