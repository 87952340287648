import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES, APP_ROUTES, CONTENT_ROUTES } from "./shared/routes";

import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
	{ 
		path: APP_ROUTES.root, 
		redirectTo: APP_ROUTES.login, 
		pathMatch: 'full' 
	},
	{ 
		path: APP_ROUTES.root, 
		component: FullLayoutComponent, 
		data: { title: 'full Views' }, 
		children: Full_ROUTES, 
		canActivate: [AuthGuard] 
	},
	{ 
		path: APP_ROUTES.root, 
		component: ContentLayoutComponent, 
		data: { title: 'content Views' }, 
		children: CONTENT_ROUTES 
	},
	{ 
		path: '**', 
		redirectTo: APP_ROUTES.error 
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})

export class AppRoutingModule {
}
