import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs/Rx'
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

import { ApiRoutes, APP_ROUTES } from '../routes';
import { LocalStorage } from '../../storage';

@Injectable()
export class AuthService {
	private authToken: string;
	private authState: BehaviorSubject<boolean>;
	private initialData: string[] = ['token'];

	constructor(private http: HttpClient, private router: Router, private ls: LocalStorage) {
		this.authState = new BehaviorSubject(false);
		this.initialData.forEach((value) => {
			this[value] = this.ls.getItem(value);
		});
	}

	/** 
	  * Login action
	  * @param payload is credentials of the user
	  */
	public login(payload): Observable<any> {
		const url: string = ApiRoutes.login;
		return this.http.post(url, payload).pipe(map((data: any) => {
			if (data.token) {
				let allModData = this.createPermissionDataForRoutes(data.permissions);
				this.ls.setItem('token', data.token);
				this.ls.setItem('nasscom-currentUser-' + data.token, JSON.stringify(data.userdetails));
				this.ls.setItem('nasscom-currentUserPermissions-' + data.token, JSON.stringify(data.permissions));
				this.ls.setItem('nasscom-currentUserRoutePermissions-' + data.token, JSON.stringify(allModData));
				this.token = data.token;
			}
			return data;
		}));
	}

	createPermissionDataForRoutes(data){
		let allowedModules = [];
		data.permissions.map((item) => {
			if(item.name == 'Dashboard'){
				if(item.permission == true){
					allowedModules.push('/dashboard')
				}
			}
			if(item.name == 'Google Analytics'){
				if(item.permission == true){
					allowedModules.push('/ga-dashboard')
				}
			}
			if(item.name == 'Management'){
				if(item.permission == true){
					let allModule =['/user','/admin-management','/role-management','/manage-permissions'];
					allModule.map((i) => {
						allowedModules.push(i);
					})
				}
			}
			if(item.name == 'Informative Pages'){
				if(item.permission == true){
					let allModule =['/about-us','/contact-us','/terms','/privacy','/disclaimer'];
					allModule.map((i) => {
						allowedModules.push(i);
					})
				}
			}
			if(item.name == 'Notifications'){
				if(item.permission == true){
						allowedModules.push('/notifications');
				}
			}
			if(item.name == 'Reports'){
				if(item.permission == true){
					allowedModules.push('/reports')
				}
			}
			if(item.name == 'Questionnaire'){
				if(item.permission == true){
					allowedModules.push('/questionnaire')
				}
			}
			if(item.name == 'Service Provider'){
				if(item.permission == true){
					allowedModules.push('/service-provider')
				}
			}
			if(item.name == "Survivor's Toolkit"){
				if(item.permission == true){
					allowedModules.push('/category')
				}
			}
			if(item.name == 'Survey'){
				if(item.permission == true){
					allowedModules.push('/survey')
				}
			}
			
		})
		return allowedModules;
	}

	//* get decoded token */
	public getToken(): string {
		const helper = new JwtHelperService();
		return helper.decodeToken(this.token);
	}

	/** 
	* Login action
	* @param token is JWT token
	*/
	public set token(token: string) {
		this.authToken = token;
		this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : '';
	}

	/** 
	* Change auth state of a user
	* @param newState of the user
	*/
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logiut action */
	public logout(): void {
		this.ls.clear();
		this.token = null;
		this.changeAuthState = !!this.token;
		this.router.navigate([APP_ROUTES.login]);
	}
}
