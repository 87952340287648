import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { AuthService } from '../shared/auth';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = (this.auth.isAuthenticated() && localStorage.getItem('token')) ? 'Bearer ' + this.auth.token : '';
		request = request.clone({
			setHeaders: {
				Authorization: token,
				tokenid: 'ACFYKz6g5HBxFJpAVR9VJx6OGqyWUn2AAlj5icytVF6lKMdDFoAiH',
				tokensecret: 'DTkjgaHuPGxJsqcXTi-zmtw2Qx8IkiNTxBF5lpss0nbSIOssnY3l',
				timezone:Intl.DateTimeFormat().resolvedOptions().timeZone
			}
		});
		return next.handle(request);
    }
}